import styled from 'styled-components';

const NoAccessStyle = styled.div`
	text-align: center;
	margin: 50px;
	padding: 30px;
	font-weight: 100;
`

function NoAccess(props) {
	return (
		<NoAccessStyle>
			<p>Beklager, men du har ikke tilgang til denne siden.</p>
		</NoAccessStyle>
	);
}

export default NoAccess;
